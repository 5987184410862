<template>
  <div class="listScroll_container">
    <div class="listScroll_content" ref="listScrollList">
      <div class="listScroll_main">
        <slot name="listScrollHeader"></slot>
        <div v-for="(item, index) in list" :key="index">
          <slot name="listItem" :citems="item" :cindex="index"></slot>
          <slot name="prompt" :citems="item" :cindex="index"></slot>
          <!-- slot-scope -->
        </div>
        <loadings v-show="isPullingUp"></loadings>
        <div class="listScroll_nonedata" v-if="list.length == 0">
          暂没有数据哟
        </div>
        <div class="listScroll_nomore" v-if="list.length > 0 && noMore">
          暂没有更多数据了
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tool from "@/common/tool";
import BScroll from "better-scroll";
import loadings from "@/components/unit/loadings";
export default {
  name: "listScroll",
  props: {
    url: {
      type: String,
      default: "",
    },
    select: {
      type: Object,
      default: () => {
        return {};
      },
    },
    format: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      search: {
        page: 1,
        pagesize: 20,
      },
      useConfig: {
        haveMore: true,
      },
      tempList: [],
      list: [],
      collect: {},
      showIcon: false,
      scroll: undefined,
      scrollTop: 0,
      noMore: false,
      isPullingUp: false,
      isPullingDown: false,
      bounce_time: 600,
    };
  },
  created() {
    this.search = { ...this.search, ...this.select };
    this.useConfig = {
      ...this.useConfig,
      ...this.config,
    };
    this.initData();
    this.initScroll();
  },
  methods: {
    initData() {
      this.getData();
    },
    async getData(isClear = false) {
      await this.questData(isClear);
      setTimeout(() => {
        this.initScroll();
      }, this.bounce_time);
    },
    searchData(search = {}) {
      this.search = { ...this.search, ...search };
      this.search.page = 1;
      this.getData(true);
      this.scroll.scrollTo(0, 0);
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BScroll(this.$refs.listScrollList, {
            click: true,
            mouseWheel: true,
            useTransition: false,
            probeType: 3,
            bounce_time: this.bounce_time,
            pullUpLoad: true,
            pullDownRefresh: {
              threshold: 100,
              stop: 56,
            },
            pullUpRefresh: {
              threshold: 100,
              stop: 56,
            },
          });
          // 下拉刷新
          this.scroll.on("pullingDown", this.pullingDownHandler);
          this.scroll.on("pullingUp", this.pullingUpHandler);
          this.scroll.on("scroll", (pos) => {
            tool.setStorage("scrollTop", pos.y || 0, sessionStorage);
          });
          const top = tool.getStorage("scrollTop", sessionStorage) || 0;
          this.scroll.scrollTo(0, top);
        } else {
          this.scroll.refresh();
        }
      });
    },
    async pullingDownHandler() {
      if (this.useConfig.haveMore) {
        this.search.page = 1;
        this.noMore = false;
        this.isPullingDown = true;
        await this.questData(true);
      }
      this.finishPullDown();
    },
    async finishPullDown() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullDown();
          resolve();
        }, 600);
        resolve();
      });
      setTimeout(() => {
        this.isPullingDown = false;
        this.scroll.refresh();
      }, this.bounce_time);
    },
    async pullingUpHandler() {
      if (this.useConfig.haveMore) {
        if (!this.noMore) {
          this.search.page += 1;
          this.isPullingUp = true;
          await this.questData();
        }
      }
      this.finishPullUp();
    },
    async finishPullUp() {
      await new Promise((resolve) => {
        setTimeout(() => {
          this.scroll.finishPullUp();
          resolve();
        }, 600);
        resolve();
      });

      setTimeout(() => {
        this.isPullingUp = false;
        this.scroll.refresh();
      }, this.bounce_time);
    },
    async questData(isClear = false) {
      try {
        let data = await this.loadData();
        if (data.length < this.search.pagesize) {
          this.noMore = true;
        }
        this.tempList = isClear ? data : this.tempList.concat(data);
        if (this.format) {
          this.list = this.formatDataList(this.tempList);
        } else {
          this.list = this.tempList;
        }
      } catch (err) {
        console.log("err :>> ", err);
      }
    },
    formatDataList(data) {
      let projectList = new Set(data.map((x) => x.project_id));
      let projectSet = [...projectList].map((id) => {
        let item = data.find((x) => x.project_id == id);
        console.log(item);
        return {
          project_id: item.project_id,
          project_name: item.project_name,
        };
      });
      // let projectSet = new Set(data.map(x=>{return {project_id:x.project_id,project_name:x.project_name}}))
      return Array.from(projectSet).map((e) => {
        let obj = { project_name: "", id: "", meetList: [] };
        (obj.project_name = e.project_name), (obj.id = e.project_id);
        obj.meetList = data.filter((a) => {
          return (
            a.project_id == e.project_id && e.project_name == a.project_name
          );
        });
        return obj;
      });
      // return meetDataList
    },
    async loadData() {
      return new Promise((resolve) => {
        let url = tool.getURL(this.url, this.search);
        this.$axios
          .get(url)
          .then((response) => {
            let list = response.data || [];
            this.$emit("receiveResponse", response);
            resolve(list);
          })
          .catch((err) => {
            console.log("err :>> ", err);
            resolve([]);
          });
      });
    },
  },
  components: {
    loadings,
  },
};
</script>
<style>
.listScroll_container {
  height: 100%;
  width: 100%;
  position: relative;
  /* background:#f1f1f1;; */
  display: flex;
  flex-direction: column;
}
.listScroll_search {
  flex: 13vw 0 0;
  padding: 2vw 3vw;
}
.listScroll_search_input {
  padding: 2vw 0vw;
  position: relative;
  font-size: 3.73vw;
  height: 8vw;
  display: flex;
  align-items: center;
}
.listScroll_search_input_text {
  flex: 1 0 0;
  position: relative;
  font-size: 3.73vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 8vw;
}
.listScroll_search_placeholder {
  flex: 20vw 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2e87f0;
}
.listScroll_search .el-input__icon {
  line-height: 5.6vw;
}
.listScroll_search input {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 5.6vw !important;
  line-height: 5.6vw !important;
  border: none;
  position: relative;
}
.listScroll_tab_list {
  flex: 10vw 0 0;
  width: 100%;
  /* display: flex; */
  background: #fff;
  /* align-items: center; */
  font-size: 3.73vw;
  padding: 0.26vw 2vw;
  border-bottom: 1px solid #ececec;
  position: relative;
}
.listScroll_tab_content_name,
.listScroll_tab_right_line {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.listScroll_tab_right_line div {
  flex: 1 0 0;
}
.listScroll_tab_right_line div > span {
  display: inline-block;
  /* height:100%; */
  width: 100%;
  text-align: center;
  border-right: 0.4vw solid #ececec;
}

.listScroll_tab_right_line:last-child div > span {
  border: none;
}
.listScroll_tab {
  flex: 1 0 0;
  text-align: center;
}
.listScroll_tab_name {
  display: inline-block;
  line-height: 10vw;
  font-size: 3.73vw;
  font-weight: 700;
  /* border-bottom:3px solid #ececec; */
}
.listScroll_tab_select .listScroll_tab_name {
  color: #9d0049;
  border-bottom: 0.39vw solid #9d0049;
}
.listScroll_content {
  flex: 1 0 0;
  width: 100%;
  overflow: hidden;
}
.listScroll_nonedata {
  height: 40vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}
.listScroll_nomore {
  height: 10vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}
.listScroll_container .box:first-child {
  margin-top: 0 !important;
}
</style>